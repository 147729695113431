import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import PageNotFound from "./PageNotFound";
import { Provider } from "react-redux";
import store from "./redux/store";
import Toast from "./common/Toast";

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Toast />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;
