import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Form from "./Form";

const RegisterForm = ({ setTriggerPopup, triggerPopup }) => {
  return (
    <>
      <Transition appear show={triggerPopup} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={() => undefined}>
          <div className="fixed inset-0 w-full ">
            <div className="w-full mymd:w-auto absolute mymd:right-[60px] mymd:top-[20px] z-50 transition-all duration-300 bg-white top-0 mymd:pt-0 py-[20px] border-b shadow pl-[10px] mymd:border-0 mymd:pl-0 mymd:shadow-none min-h-[64px]"></div>
            <div
              className="absolute mymd:right-[25px] top-[20px] rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px] z-50 right-[17px]"
              onClick={() => setTriggerPopup(false)}
            >
              <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px] font-medium">
                x
              </div>
            </div>

            <div className="w-[100vw] mymd:w-full h-full overflow-auto scroll-smooth	bg-white scrollbar-hide mymd:scrollbar-default">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform bg-white transition-all ">
                  <div className="flex justify-center w-full mt-[60px] mymd:mt-[30px] mb-[30px]">
                    <Form
                      setTriggerPopup={setTriggerPopup}
                      triggerPopup={triggerPopup}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default RegisterForm;
