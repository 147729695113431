export const InterestedIn = [
  { label: "Delegate Attendance", value: 2 },
  { label: "Sponsorship", value: 3 },
  { label: "Media Partership", value: 4 },
  { label: "Speaking Oppourtunity", value: 5 },
];

export const HeardFrom = [
  { label: "Email", value: 2 },
  { label: "Linkedin", value: 3 },
  { label: "Twitter", value: 4 },
  { label: "Facebook", value: 5 },
  { label: "Instagram", value: 6 },
  { label: "News/Other Websites", value: 7 },
  { label: "Referral", value: 8 },
];

export const TypeOfAttendee = [
  { label: "End User Complimentary", value: 2 },
  { label: "Vendor Delegate Pass", value: 3 },
];
