import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    message: "",
    category: "",
    show: false,
  },
  reducers: {
    setShowToast: (state, action) => {
      return {
        message: action.payload.message,
        category: action.payload.category,
        show: true,
      };
    },

    setHideToast: (state, action) => {
      return null;
    },
  },
});

export const { setShowToast, setHideToast } = toastSlice.actions;
export default toastSlice.reducer;
