import React, { useEffect, useState } from "react";
import useDebounce from "../helper/useDebounce";

const CountryDropdown = ({
  countryResult,
  setCountryResult,
  label,
  required,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchValue = useDebounce(searchTerm, 700);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (countryResult?.name) {
      setSearchTerm(countryResult?.name);
    }
  }, [countryResult]);

  useEffect(() => {
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      " bXJra0hXRXk2UTdZWmdYTzZDQU53TEVyT1JIWUJ3YmxRZnA3dEpqUA=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(`${"https://api.countrystatecity.in/v1/countries"}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (debouncedSearchValue === "") {
          const firstFourCountries = result.slice(0, 100);
          setSearchResults(firstFourCountries);
        } else {
          const filteredCountries = result.filter((country) =>
            country.name.toLowerCase().startsWith(searchTerm.toLowerCase())
          );

          setSearchResults(filteredCountries);
        }
      })
      .catch((error) => console.log("error", error));
  }, [debouncedSearchValue]);

  return (
    <div>
      <div className={`relative mymd:w-[500px] mb-[27px]`}>
        <div
          className="block text-[15px] font-[400] mb-1 text-gray-500"
          htmlFor="random_id"
        >
          {label || "Country"}{" "}
          {required && <span className="text-rose-500">*</span>}
        </div>

        <input
          type="text"
          id="random_id"
          required={required}
          value={searchTerm}
          onFocus={() => setShowResults(true)}
          onBlur={() => setShowResults(false)}
          onChange={(event) => setSearchTerm(event.target.value?.trim())}
          className={`form-input w-full`}
          autoComplete="new-password"
        />

        {showResults && (
          <div className="border max-h-[110px] absolute w-[100%] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
            {searchResults.map((result) => (
              <span
                onMouseDown={(event) => {
                  setSearchTerm(result?.name);
                  setCountryResult(result);
                  event.stopPropagation();
                }}
                key={result?.id}
                className="w-[100%] flex h-[40px] items-center pl-2 text-[16px] font-[500] cursor-pointer hover:bg-gray-200"
              >
                {result?.name}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryDropdown;
